'use client';
import React, { useState, useRef } from 'react';
import Link from 'next/link';

export default function PostCategoryTags({
  className,
  tags,
}: {
  className?: string;
  tags: any;
}) {
  // Refs for the scroll container
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  // State to handle drag functionality
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsMouseDown(true);
    if (scrollContainerRef.current) {
      setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
      setScrollLeft(scrollContainerRef.current.scrollLeft);
      scrollContainerRef.current.style.cursor = 'grabbing';
    }
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.cursor = 'grab';
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.cursor = 'grab';
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isMouseDown) return;
    if (scrollContainerRef.current) {
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 2; // Adjust scrolling speed by changing multiplier
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleDragStart = (e: React.DragEvent) => {
    e.preventDefault(); // Prevent link dragging behavior
  };

  return (
    <>
      <div className='post-tag pointer-events-none absolute inset-y-0 right-0 top-0 z-[1] h-6 w-12 bg-gradient-to-l from-white to-transparent'></div>

      <div
        className='scrollbar-hide relative flex h-6 w-full flex-nowrap gap-2 overflow-x-scroll'
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {tags?.map(
          (tag: any, index: number) =>
            tag?.name && (
              <Link
                className={`flex h-full items-center whitespace-nowrap rounded-md border border-[#EFE9DB] bg-[rgba(239,233,219,0.40)] px-2 py-1 font-sans text-[10px] font-semibold uppercase tracking-[1.7px] transition-all duration-200 hover:border hover:border-[#FFCD00] hover:bg-gradient-to-r hover:from-[#FFCF41] hover:to-[#FFE283] ${className || ''}`}
                key={index}
                href={`/blog/search?tag=${tag?.slug}`}
                onDragStart={handleDragStart} // Prevent drag on links
              >
                {tag?.name}
              </Link>
            )
        )}
      </div>
    </>
  );
}
